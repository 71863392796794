export default function getApiLocalPort(path) {
  if (path === "user") {
    return 9000;
  } else if (path === "client") {
    return 9001;
  } else if (path === "security") {
    return 9002;
  } else if (path === "organization") {
    return 9004;
  } else if (path === "message") {
    return 9003;
  } else if (path === "account") {
    return 9005;
  } else if (path === "approval.flow") {
    return 9006;
  } else if (path === "vendor") {
    return 9007;
  } else if (path === "unit") {
    return 9008;
  } else if (path === "product") {
    return 9009;
  } else if (path === "customer") {
    return 9010;
  } else if (path === "payment") {
    return 9011;
  } else if (path === "sales.area") {
    return 9012;
  } else if (path === "event") {
    return 9013;
  } else if (path === "sales.planning") {
    return 9014;
  } else if (path === "comment") {
    return 9015;
  } else if (path === "purchase") {
    return 9016;
  } else if (path === "purchase.planning") {
    return 9017;
  } else if (path === "opex.planning") {
    return 9019;
  } else if (path === "capex.planning") {
    return 9020;
  } else if (path === "pl.planning") {
    return 9022;
  } else if (path === "dynamic.report") {
    return 9021;
  } else if (path === "integration") {
    return 9023;
  } else if (path === "cash.flow") {
    return 9024;
  }
}
